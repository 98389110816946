import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import { Checkbox, MenuItem, Select, ListItemText, TextField  } from '@mui/material'
import ButtonCountries from 'components/buttons/Button_Countries'
import ButtonOffer from 'components/buttons/Button_Offer.js'
import ButtonWithdrawal from 'components/buttons/Button_Withdrawal'
import ActionButton from 'components/buttons/Action_Button'
import OffersDoneActionButton from 'components/buttons/Offers_Done_Action_Button.js';
import Images from 'utils/Images'
import Alerts from 'utils/Alerts.js';
import Formatting from 'utils/Formatting.js';
import Backend from 'Backend.js';
import FormControl from '@mui/material/FormControl';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CopyToCliboard from 'components/buttons/CopyToCliboard';
import Alert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Tooltip from '@mui/material/Tooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';

const backendURLs = Backend.backendURLs;
const getCsvURL = backendURLs.getCsvURL;

const content = require('content.json');
const statusOfTransaction = content.status_of_transaction;
const showButtonText = content.show;
const exportReportButtonText = content.export_report;

const offerColumns = [
    'OfferOfDay',
    'IsSpecial',
    'GivesCompetitionCoins',
    'GivesUseCoins',
    'GivesBonusToReferrals',
    'IsFree',
    'X2Offer',
    'IsShowingOnHP',
    'DisableSubmitReport',
    'IsStockOffer'
];


const categoryData = {
    1: 'Deposit',
    2: 'Gaming',
    3: 'Engagement',
    4: 'Surveys',
    5: 'Trivia',
    6: 'Knowledge'
};

const subCategoryData = {
    0: 'Other',
    1: 'Sign Up',
    2: 'Use App',
    3: 'Download',
    4: 'Double Your Money',
    5: 'Survey',
    6: 'Game',
    7: 'Free Trial',
    8: 'TreatCoin Exclusive'
};

const marketingTypes = {
    1:'Cost Per Click',
    2:'Cost Per Mille',
    3:'Cost Per Acquisition',
    4:'Cost Per Lead',
    5:'Cost Per Install',
    6:'Cost Per Sale',
    7:'Cost Per Engagement'
}

const CellRow = ({
    columns,
    item,
    selectedUser,
    handleChoose,
    handleWithdrawals,
    handleOpenModal,
    handleOfferChange,
    searchBy,
    updateItems,
    handleMultiModal,
    setItems,
}) => {
    const {
        avatar,
        name,
        username,
        ID,
        email,
        Email,
        platform,
        treat_coins,
        system_got_payout,
        heading,
        provider,
        company,
        actionName,
        payout,
        usersPayout,
        payoutForTreatCoin,
        payout1gen,
        usersDidOffer,
        usersClickedOnOffer,
        conversionRate,
        ctrRate,
        impressionRate,
        time,
        country,
        action,
        icon,
        Icon,
        Banner,
        AppName,
        PlatformType,
        Link,
        Countries,
        Status,
        Description,
        Requirements,
        DetailedDescription,
        CreationTime,
        Coins,
        CoinsReferral,
        Coins2ndGenReferral,
        Coins3rdGenReferral,
        Percentage,
        PercentageReferral,
        Percentage2ndGenReferral,
        Percentage3rdGenReferral,
        Level,
        Provider,
        OfferType,
        UserDailyCap,
        OfferTheme,
        CashbackCategory,
        Category,
        HashTags,
        SystemPayout,
        Impressions,
        Clicks,
        CTR,
        ConversionRate,
        ClicksToGoToOffer,
        StartDate,
        EndDate,
        FileLink,
        IsMultiEvent,
        OfferSteps,
        MinBalanceToShow,
        MaxBalanceToShow,
        ClicksCap,
        AvailableForReferrals,
        DisplayToPaidUsersOnly,
        DisplayRecentActivityThreshold,
        DisplayCap,
        IsFree,
        FreeMinBalanceToShow,
        UserID,
        UserName,
        CategoryHPID,
        SubCategories,
        Guides,
        MainTitle,
        UniqueOfferID,
        Position,
        OfferTypeID,
        PostbackUrl,
        ProviderID,
        GuidesVideoURL,
        GuidesImageURL,
        TotalClickthroughCap,
        StatusID,
        IsStockOffer,
        CompletionsCap,
        CompletionsCapDuration,
        ReviewRequired,
        ReviewAllowed,
        ReviewTopicName,
        ReviewTopicID,
        ExpirationDate,
        MarketingType,
    } = item;

    let guidesArr = []
    try {
        guidesArr = JSON.parse(Guides);
    } catch (e) {
        
    }

    const [offerType, setOfferType] = useState(OfferType);
    const [userDailyCap, setUserDailyCap] = useState(UserDailyCap);
    const [offerTheme, setOfferTheme] = useState(OfferTheme || "Cashback");
    const [level, setLevel] = useState(Level || "Easy");
    const [hashTags, setHashTags] = useState(HashTags);
    const [category, setCategory] = useState(Category || "Add");
    const [cashbackCategory, setCashbackCategory] = useState(CashbackCategory || "Add");
    const [detailedDescription, setDetailedDescription] = useState(DetailedDescription);
    const [description, setDescription] = useState(Description);
    const [requirements, setRequirements] = useState(Requirements)
    const [appName, setAppName] = useState(AppName || "Add");
    const [systemPayout, setSystemPayout] = useState(SystemPayout || 0);    
    const [coins, setCoins] = useState(Coins || 0);
    const [coinsRef, setCoinsRef] = useState(CoinsReferral || 0);
    const [coins2nd, setCoins2nd] = useState(Coins2ndGenReferral || 0);
    const [coins3rd, setCoins3rd] = useState(Coins3rdGenReferral || 0);
    const [percentage, setPercentage] = useState(Percentage || "Add");
    const [percentageRef, setPercentageRef] = useState(PercentageReferral || "Add");
    const [percentage2nd, setPercentage2nd] = useState(Percentage2ndGenReferral || "Add");
    const [percentage3rd, setPercentage3rd] = useState(Percentage3rdGenReferral || "Add");
    const [platformType, setPlatformType] = useState(PlatformType || "Set")
    const [checkBoxesValues, setCheckBoxesValues] = useState({});
    const [mainCategory, setMainCategory] = useState(CategoryHPID || "");
    const [marketingType, setMarketingType] = useState(MarketingType || "");
    const [subCategory, setSubCategory] = useState(SubCategories ? SubCategories.split(",") : []);
    const [guides, setGuides] = useState(guidesArr);
    const [mainTitle, setMainTitle] = useState(MainTitle);
    const [uniqueOfferID, setUniqueOfferID] = useState(UniqueOfferID);
    const [position, setPosition] = useState(Position);
    const [offerTypeID, setOfferTypeID] = useState(OfferTypeID);
    const [countries, setCountries] = useState(Countries);
    const [clickthrough, setClickthrough] = useState(Link);
    const [isStockOffer, setIsStockOffer] = useState(IsStockOffer);
    const [totalClickthroughCap, setTotalClickthroughCap] = useState(TotalClickthroughCap);
    const [completionsCap, setCompletionsCap] = useState(CompletionsCap);
    const [completionsCapDuration, setCompletionsCapDuration] = useState(CompletionsCapDuration);
    const [reviewRequired, setReviewRequired] = useState(ReviewRequired);
    const [reviewAllowed, setReviewAllowed] = useState(ReviewAllowed);
    const [reviewTopicName, setReviewTopicName] = useState(ReviewTopicName);


    const isSystemOffer = [5,6].includes(offerTypeID);
    let cellClassName = isSystemOffer ? "clickable-on-disabled" : ""

    const offerOfDayChangeHandler = evt => {
        handleOfferChange(ID, evt.target.value, evt.target.checked ? 1 : 0);

        if(evt.target.value == "IsStockOffer"){
            setIsStockOffer(evt.target.checked);
        }

        setCheckBoxesValues(prev => {
            const newValues = { ...prev };
            newValues[evt.target.value] = evt.target.checked ? 1 : 0;
            return newValues;
        });
    };

    const thenCallback = async res => {
        if (
            res.status === Backend.backendResponseCodes.internalServerErrorCode ||
            res.status === Backend.backendResponseCodes.serviceUnavailableCode
        ) {
            return null;
        } else if (res.status === Backend.backendResponseCodes.unauthorizedCode) {
            Backend.logOut();
        } 
        else {
            let result = await res.json();
            return result;
        }
    };

    const downloadURL = (url, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleGetCsvUrl = (linkUrl) => {
        const params = new URLSearchParams();
        params.append("fileLink", linkUrl);

        fetch(getCsvURL + `?${params}`, {
            method: Backend.backendMethods.get,
            headers: Backend.generateHeader(),
        })
            .then(thenCallback)
            .then(result => result?.url && downloadURL(result.url, "download.csv"))
            .catch(err => null);
    }

    let disabled = field => {
        if (checkBoxesValues['Coins'] === 0 && [
            'CookingRequired',
            'GivesCompetitionCoins',
            'GivesUseCoins',
            'GivesBonusToReferrals'
        ].includes(field)) {
            return true;
        } else if (checkBoxesValues['GivesUseCoins'] === 0 && ['CookingRequired'].includes(field)) {
            return true;
        } else if (checkBoxesValues['Coins'] === 0 && ['GivesUseCoins'].includes(field)) {
            return true;
        } else {
            return false;
        }
    };

    const onMultipleSelectChange = (event) => {
        const value = event.target.value;
        const fieldName = event.target.name;

        setSubCategory(
          typeof value === 'string' ? value.split(',') : value,
        );
        
        handleOfferChange(ID, fieldName, value.join(","));
    };

    useEffect(() => {
        setCheckBoxesValues(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {columns.map(({ field, width }, idx) => {
                let index = idx + 1
                let src
                const colWidth = width ? {width: `${width}px`} : {};

                switch (platform) {
                    case 'telegram':
                        src = Images.imageURL('telegram.svg')
                        break
                    case 'twitter':
                        src = Images.imageURL('twitter.svg')
                        break
                    case 'facebook':
                        src = Images.imageURL('facebook.svg')
                        break
                    default:
                        break
                }

                if (field === 'label') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <img src={src} alt="platform" width={80} height={80} />
                        </div>
                    )
                }
                if (field === 'UniqueOfferID') {
                    return (
                        <div className="grid-list_basic-cell sticky UniqueOfferID" style={colWidth} key={index}>
                            {UniqueOfferID}
                        </div>
                    )
                }

                if (field === 'Banner') {
                    src = Banner
                    return <div className="grid-list_basic-cell clickable-on-disabled" style={colWidth} key={index} onClick={() => {
                        Alerts.showOfferBannerAlert({ offerID: ID, updateItems })
                    }}>
                            {
                                Banner ?
                                <img src={src} alt="platform" width={80} height={80} /> :
                                content.add
                            }
                        </div>
                }

                if (field === 'icon' || field === 'Icon') {
                    src = icon || Icon;
                    return <div className="grid-list_basic-cell clickable-on-disabled" style={colWidth} key={index}
                        onClick={() => {
                            Alerts.showOfferIconAlert({ offerID: ID, updateItems });
                        }}
                    >
                            {
                                src ?
                                <img src={src} alt="platform" width={80} height={80} /> :
                                content.add
                            }
                        </div>
                }

                if (field === 'checkbox') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                checked={selectedUser === ID}
                                onChange={event => handleChoose(ID)}
                            />
                        </div>
                    )
                }

                if (field === 'Coins') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ( (offerType.name || offerType) !== "Percentage offer") ?
                            Alerts.showAddCoinsAlert(ID, coins, (value) => setCoins(value)):
                            (null)
                        }>
                            { coins }
                        </div>
                    )
                }

                if (field === 'CoinsReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) !== "Percentage offer") ?
                            Alerts.showAddCoinsReferralAlert(ID, coinsRef, (value) => setCoinsRef(value)):
                            (null)
                        }>
                            { coinsRef }
                        </div>
                    )
                }

                if (field === 'Coins2ndGenReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) !== "Percentage offer") ?
                            Alerts.showAddCoins2ndGenReferralAlert(ID, coins2nd, (value) => setCoins2nd(value)):
                            (null)
                        }>
                            { coins2nd }
                        </div>
                    )
                }

                if (field === 'Coins3rdGenReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) !== "Percentage offer") ?
                            Alerts.showAddCoins3rdGenReferralAlert(ID, coins3rd, (value) => setCoins3rd(value)):
                            (null)
                        }>
                            { coins3rd }
                        </div>
                    )
                }

                if (field === 'Percentage') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) === "Percentage offer") ?
                            Alerts.showAddPercentageAlert(ID, percentage, (value) => setPercentage(value)):
                            (null)
                        }>
                            { percentage }
                        </div>
                    )
                }
                
                if (field === 'PercentageReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) === "Percentage offer") ?
                            Alerts.showAddPercentageReferralAlert(ID, percentageRef, (value) => setPercentageRef(value)):
                            (null)
                        }>
                            { percentageRef }
                        </div>
                    )
                }
                
                if (field === 'Percentage2ndGenReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) === "Percentage offer") ?
                            Alerts.showAddPercentage2ndGenReferralAlert(ID, percentage2nd, (value) => setPercentage2nd(value)):
                            (null)
                        }>
                            { percentage2nd }
                        </div>
                    )
                }
                
                if (field === 'Percentage3rdGenReferral') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            ((offerType.name || offerType) === "Percentage offer") ?
                            Alerts.showAddPercentage3rdGenReferralAlert(ID, percentage3rd, (value) => setPercentage3rd(value)):
                            (null)
                        }>
                            { percentage3rd }
                        </div>
                    )
                }

                if (field === 'Provider') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { Provider }
                        </div>
                    )
                }

                if (field === 'Level') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            Alerts.showDifficultyLevelSelectAlert(level, ID, (value) => setLevel(value))
                        }>
                            { level } 
                        </div>
                    )
                }

                if (field === 'OfferTheme') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() =>
                            Alerts.showOfferThemesSelectAlert(offerTheme, ID, (value) => setOfferTheme(value))
                        }>
                            { offerTheme }
                        </div>
                    )
                }

                if (field === 'CashbackCategory') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            (offerTheme === "Cashback") ?
                            Alerts.showCashbackCategorySelectAlert(cashbackCategory, ID, (value) => setCashbackCategory(value)) :
                            null
                        }>
                            { cashbackCategory }
                        </div>
                    )
                }

                if (field === 'Category') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            Alerts.showCategorySelectAlert(category, ID, (value) => setCategory(value))
                        }>
                            { category }
                        </div>
                    )
                }

                if (offerColumns.includes(field)) {
                    return (
                        <div className={`grid-list_basic-cell ${(field == 'IsShowingOnHP' ? cellClassName : '')}`} style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                value={field}
                                checked={Boolean(checkBoxesValues[field])}
                                onChange={offerOfDayChangeHandler}
                                disabled={disabled(field)}
                            />
                        </div>
                    )
                }

                if (field === 'HashTags') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            Alerts.showHashtagsSelectAlert(hashTags, ID, (value) => setHashTags(value))
                        }>
                            { hashTags || "Add" }
                        </div>
                    )
                }

                if (field === 'SystemPayout') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            (ProviderID > 1000) ?
                            Alerts.showAddSystemPayoutAlert(ID, systemPayout.replace('$',''), (value) => setSystemPayout('$' + value)):
                            (null)
                        }>
                            {systemPayout} {percentage === 'N/A' ? '$' : '%'}
                        </div>
                    )
                }

                if (field === 'FreeOffer') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => Alerts.showAddFreeOfferModal(
                            {
                                ID,
                                Percentage: Percentage !== "N/A",
                                MinBalanceToShow: IsFree ? FreeMinBalanceToShow : MinBalanceToShow,
                                MaxBalanceToShow: IsFree ? FreeMinBalanceToShow : MaxBalanceToShow,
                                ClicksCap,
                                AvailableForReferrals,
                                DisplayToPaidUsersOnly,
                                DisplayRecentActivityThreshold,
                                DisplayCap,
                                IsFree,
                            }
                        )}>
                            <p className="desc">{IsFree ? content.free_offer_view : content.free_offer_setup}</p>
                        </div>
                    )
                }


                if (field === 'Impressions') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { Impressions }
                        </div>
                    )
                }

                if (field === 'Clicks') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { Clicks }
                        </div>
                    )
                }

                if (field === 'CTR') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { CTR }
                        </div>
                    )
                }

                if (field === 'ConversionRate') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { ConversionRate }
                        </div>
                    )
                }

                if (field === 'ClicksToGoToOffer') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            { ClicksToGoToOffer }
                        </div>
                    )
                }

                if (field === 'DetailedDescription') {
                    return (
                        <Tooltip title={detailedDescription ?? ''}>
                        <div 
                            className="grid-list_basic-cell" 
                            style={colWidth}
                            key={index} 
                            onClick={() => Alerts.showAddDescriptionAlert(
                                    ID, 
                                    detailedDescription, 
                                    (value) => setDetailedDescription(value)
                                )
                            }
                        >
                            { 
                                typeof(detailedDescription) == "string" ?
                                detailedDescription :
                                "Add"                            
                            }
                        </div>
                        </Tooltip>
                    )
                }

                if (field === 'Description') {
                    
                    return (      
                        <Tooltip title={ description || "" }>                
                            <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index} onClick={() => 
                                Alerts.showAddDescriptionAlert(ID, uniqueOfferID, description, (value) => setDescription(value), null, 'description')
                            }>
                                { description || "Add" }
                            </div>
                        </Tooltip>  
                    )
                }
                if (field === 'Requirements') {
                    
                    return (      
                        <Tooltip title={requirements || "" }>                
                            <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index} onClick={() => 
                                Alerts.showAddDescriptionAlert(ID, uniqueOfferID, requirements, (value) => setRequirements(value), 'Change requirements', 'Requirements')
                            }>
                                {requirements || "Add" }
                            </div>
                        </Tooltip>  
                    )
                }

                if (field === 'MainTitle') {
                    return (     
                        <Tooltip title={ mainTitle || "" }>                  
                            <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index} onClick={() => 
                                Alerts.showMainTitleAlert(ID, uniqueOfferID, mainTitle, (value) => setMainTitle(value))
                            }>
                                { mainTitle || "Add" }
                            </div>
                        </Tooltip> 
                    )
                }

                if (field === 'AppName') {
                    return (
                        <div className="grid-list_basic-cell sticky AppName" style={colWidth} key={index} onClick={() => 
                            Alerts.showAddAppnameAlert(ID, appName, (value) => setAppName(value))
                        }>
                            { appName }
                        </div>
                    )
                }

                if (field === 'logo') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <img alt={item.imageURL} src={item.imageURL || Images.imageURL('fb-icon.svg')} />
                        </div>
                    )
                }

                if (field === 'ID') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">#{ID}</p>
                        </div>
                    )
                }

                if (field === 'Countries') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <ButtonCountries 
                                countries={countries} 
                                editable={ProviderID > 1000} 
                                offerId={ID}
                                onChange={(e)=>setCountries(e)}
                            />
                        </div>
                    )
                }

                if (field === 'system_got_payout') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{system_got_payout ? 'Yes' : 'No'}</p>
                        </div>
                    )
                }

                if (field === 'avatar') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <img src={avatar} alt={username} name={username} />
                        </div>
                    )
                }

                if (field === 'Status') {
                    let alertType;
                    switch(StatusID){
                        case 1:
                            alertType = "success";
                            break;
                        case 2:
                        case 3:
                        case 4:
                            alertType = "error";
                            break;
                        case 5:
                            alertType = "warning";
                            break;
                        case 100:
                            alertType = "info";
                            break;
                        default:
                            break;
                    }
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p>
                                <Alert severity={alertType}>
                                    {Status}
                                </Alert>
                            </p>
                        </div>
                    )
                }

                if (field === 'username') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc username">{username}</p>
                        </div>
                    )
                }

                if (field === 'name') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{name}</p>
                        </div>
                    )
                }

                if (field === 'PlatformType') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            (ProviderID > 1000) ?
                            Alerts.showSetPlatformTypeAlert(platformType, ID, (value) => setPlatformType(value)):
                            (null)
                        }>
                            { platformType }
                        </div>
                    )
                }

                if (field === 'OfferType') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index} onClick={() => 
                            Alerts.showOfferTypesSelectAlert(offerType === 'Daily offer' ?
                            Formatting.format(content.daily_offer_display, ["(userDailyCap)"], [userDailyCap]) : offerType, ID, (value) => setOfferType(value))
                        }>
                            { offerType?.name || (offerType === 'Daily offer' ?
                                Formatting.format(content.daily_offer_display, ["(userDailyCap)"], [userDailyCap]) : offerType) || "Add" }
                        </div>
                    )
                }

                if (field === 'heading') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{heading}</p>
                        </div>
                    )
                }

                if (field === 'provider') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{provider}</p>
                        </div>
                    )
                }

                if (field === 'company') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{company}</p>
                        </div>
                    )
                }

                if (field === 'actionName') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{actionName}</p>
                        </div>
                    )
                }

                

                if (field === 'payout') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{payout}</p>
                        </div>
                    )
                }

                if (field === 'usersPayout') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{usersPayout}</p>
                        </div>
                    )
                }

                if (field === 'payoutForTreatCoin') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{payoutForTreatCoin}</p>
                        </div>
                    )
                }

                if (field === 'payout1gen') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{payout1gen}</p>
                        </div>
                    )
                }

                if (field === 'usersDidOffer') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{usersDidOffer}</p>
                        </div>
                    )
                }

                if (field === 'usersClickedOnOffer') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{usersClickedOnOffer}</p>
                        </div>
                    )
                }

                if (field === 'conversionRate') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{conversionRate}</p>
                        </div>
                    )
                }

                if (field === 'ctrRate') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{ctrRate}</p>
                        </div>
                    )
                }

                if (field === 'impressionRate') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{impressionRate}</p>
                        </div>
                    )
                }

                if (field === 'time') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{time}</p>
                        </div>
                    )
                }

                if (field === 'CreationTime') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{CreationTime.slice(0, 10)}</p>
                        </div>
                    )
                }

                if (field === 'country') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{country}</p>
                        </div>
                    )
                }

                if (field === 'ValidationsDateRange') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <p className="desc">{StartDate + " - " + EndDate}</p>
                        </div>
                    )
                }

                if (field === 'Link') { 
                    return (
                        <div
                            className="grid-list_basic-cell clickable-on-disabled"
                            style={colWidth}
                            key={index}
                            onClick={() => {
                                if (field === 'Link') {
                                if (ProviderID > 1000) {
                                    return Alerts.showClickthroughAlert(
                                        ID,
                                        clickthrough,
                                        (value) => {
                                            setClickthrough(value);
                                        }
                                    );
                                }
                            }}
                        }
                            >
                                {clickthrough}
                            </div>
                    )
                }

                if (field === 'action_withdrawal') {
                    if (item.status === 'pending') {
                        return (
                            <div className="grid-list_basic-cell" style={colWidth} key={index}>
                                <ButtonWithdrawal
                                    setItems={setItems}
                                    handleWithdrawals={handleWithdrawals}
                                    Component={DoneIcon}
                                    variant="approve"
                                    ID={ID}
                                    userID={UserID}
                                    username={UserName}
                                    email={Email}
                                    platform={platform}
                                    treat_coins={treat_coins}
                                    withdrawal_amount={Coins}
                                />
                                <ButtonWithdrawal
                                    setItems={setItems}
                                    handleWithdrawals={handleWithdrawals}
                                    Component={ClearIcon}
                                    variant="reject"
                                    ID={ID}
                                    userID={UserID}
                                    username={UserName}
                                    email={Email}
                                    platform={platform}
                                    treat_coins={treat_coins}
                                    withdrawal_amount={Coins}
                                />
                            </div>
                        )
                    }

                    if (searchBy === 'Offers') {
                        return (
                            <div className="grid-list_basic-cell" style={colWidth} key={index}>
                                <ButtonOffer
                                    handleWithdrawals={handleWithdrawals}
                                    Component={null}
                                    variant={action}
                                    status={item.status}
                                    ID={ID}
                                    username={username}
                                    email={email}
                                    platform={platform}
                                    treat_coins={treat_coins}
                                />
                            </div>
                        )
                    }

                    return null
                }

                if (field === 'IsMultiEvent') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            {IsMultiEvent
                                ? <ActionButton
                                    type={'edit'}
                                    text={'Edit'}
                                    onClick={() => { 
                                        handleMultiModal({ID, OfferSteps});
                                    }}
                                />
                                : <></>}
                        </div>
                    );
                }

                if (field === 'OffersAction') {
                    const toActivate = [1].includes(StatusID);
                    const toDeActivate = [2,5,100].includes(StatusID);
                    return (
                        <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index}>
                            {
                                toActivate || toDeActivate ? 
                                <>
                                    <ActionButton
                                        type={toActivate ? 'reject' : 'approve'}
                                        text={(
                                            isSystemOffer ? "Refresh" : 
                                            toActivate ? 'Deactivate' : 
                                            'Activate'
                                        )}
                                        className={isSystemOffer ? "refresh" : ''}
                                        onClick={() => {
                                            handleOpenModal({ ID, StatusID, isSystemOffer })
                                        }}
                                    /> 
                                    {ProviderID > 1000 && PostbackUrl && (
                                        <CopyToCliboard 
                                            showIcon 
                                            text="Postback URL"
                                            copyText={PostbackUrl}
                                        />
                                    )}
                                </>:
                                <></>
                            }
                        </div>
                    )
                }

                if (field === 'OffersDoneStatsAction' || field === 'OffersDoneAdminAction') {
                    const text = (field === 'OffersDoneStatsAction') ?
                        showButtonText :
                        exportReportButtonText;
                    const clickHandler = (field === 'OffersDoneStatsAction') ?
                        () => console.log('show') :
                        () => {
                            const fileName = FileLink?.split("/").pop(); 
                            handleGetCsvUrl(fileName)
                        };
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            {
                                <OffersDoneActionButton
                                    text={text}
                                    clickHandler={clickHandler}
                                /> 
                            }
                        </div>
                    )
                }

                if (field === 'CategoryHPID') {
                    
                    return (
                        <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index}>
                            <Select
                                value={mainCategory}
                                size="small"
                                variant="standard"
                                onChange={event => {
                                    setMainCategory(event.target.value)
                                    handleOfferChange(ID, 'CategoryHPID', event.target.value);
                                }}
                            >
                                {Object.keys(categoryData).map((item, idx) => (
                                    <MenuItem value={item} key={idx}>
                                        {categoryData[item]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )
                }
                if (field === 'MarketingType') {
                    
                    return (
                        <div className={`grid-list_basic-cell ${cellClassName}`} style={colWidth} key={index}>
                            <Select
                                value={marketingType}
                                size="small"
                                variant="standard"
                                onChange={event => {
                                    setMarketingType(event.target.value)
                                    handleOfferChange(ID, 'MarketingType', event.target.value);
                                }}
                            >
                                {Object.keys(marketingTypes).map((item, idx) => (
                                    <MenuItem value={item} key={idx}>
                                        {marketingTypes[item]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )
                }

                if (field === 'SubCategories') {
                    
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <FormControl sx={{ m: 1, maxWidth: 150 }}>
                                <Select
                                    size="small"
                                    variant="standard"
                                    name={field}
                                    multiple
                                    value={subCategory}
                                    onChange={onMultipleSelectChange}
                                    renderValue={(selected) => {
                                        return selected.map(s => subCategoryData[s]).join(', ')
                                    }}
                                    >
                                    {Object.keys(subCategoryData).map((item, idx) => (
                                        <MenuItem value={item} key={idx}>
                                            <Checkbox checked={subCategory.indexOf(item) > -1} />
                                            <ListItemText primary={subCategoryData[item]} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )
                }

                if (field === 'Guides') {
                    
                    return (
                        <div 
                            className={`grid-list_basic-cell ${cellClassName}`}
                            style={colWidth}
                            key={index} 
                            onClick={() => Alerts.showGuidesAlert(
                                    ID, 
                                    uniqueOfferID,
                                    guides, 
                                    (value) => setGuides(value)
                                )
                            }
                        >
                            {guides != "" ? "See Guides" : "Add"}
                        </div>
                    )
                }

                if (field === 'Position') {
                    
                    return (
                        <div 
                            className="grid-list_basic-cell clickable-on-disabled" 
                            style={colWidth}
                            key={index} 
                            onClick={() => Alerts.showPositionAlert(
                                    ID, 
                                    position, 
                                    (value) => {
                                        setPosition(value);
                                        updateItems();
                                    }
                                )
                            }
                        >
                            {Position < 1000000 ? <> {Position} <BookmarkIcon color="primary" sx={{fontSize: "25px"}} /></> : "Add"}
                        </div>
                    )
                }

                if (field === 'GuidesVideoURL') {
                    
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}
                            onClick={() => {
                                Alerts.showVideoGuidelineUploaderAlert(UniqueOfferID, GuidesVideoURL, GuidesImageURL, () => updateItems() );
                            }}
                        >
                            {
                                GuidesImageURL ? <img src={GuidesImageURL} width={80} height={80} /> 
                                    : GuidesVideoURL ? content.edit
                                    : content.add
                            }
                        </div>
                    )

                }

                if (field === 'CookingRequired') {
                    
                        return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                value={field}
                                checked={Boolean(checkBoxesValues[field])}
                                disabled={ProviderID <= 1000}
                                onChange={(el) => {
                                    if(Boolean(checkBoxesValues[field])){
                                        Alerts.showConfirmAlert("Your are about to set this offer as Autocooked", 
                                            (confirm) => {
                                                if(confirm){
                                                    el.target.checked = !el.target.checked
                                                    offerOfDayChangeHandler(el);
                                                }
                                            }
                                        )
                                    }else{
                                        offerOfDayChangeHandler(el);
                                    }
                                }}
                            />
                        </div>
                    )
                   
                }

                if (field === 'StockSettings') {

                    return (
                        <div 
                            className="grid-list_basic-cell clickable-on-disabled" 
                            style={colWidth}
                            key={index} 
                            onClick={() => isStockOffer && Alerts.showStockOffersConfigurationAlert(
                                    UniqueOfferID, 
                                    (value) => {
                                        //updateItems();
                                    }
                                )
                            }
                        >
                            {isStockOffer ? "Edit" : "N/A"}
                        </div>
                    )
                }


                if (field === 'Is2ndConversionOffer') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                value={field}
                                checked={Boolean(checkBoxesValues[field])}
                                onChange={(el) => {
                                    offerOfDayChangeHandler(el)
                                    if(!Boolean(checkBoxesValues[field])){
                                        Alerts.show2ndConversionAlert(UniqueOfferID)
                                    }
                                }}
                            />
                            {Boolean(checkBoxesValues[field]) && (
                                <span 
                                    className='text-underline-hover margin-left-5'
                                    onClick={() => Alerts.show2ndConversionAlert(UniqueOfferID)}
                                >
                                    Edit
                                </span>
                            )}
                        </div>
                    )
                }
                   
                

                if (field === 'TotalClickthroughCap') {
                    
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}
                            onClick={() => {
                                Alerts.showClickthroughCapAlert(ID, totalClickthroughCap, (newVal) => setTotalClickthroughCap(newVal));
                            }}
                        >
                            {totalClickthroughCap ?? "N/A" }
                        </div>
                    )
               
                }


                if (field === 'CompletionsCap') {
                    
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <TextField 
                                inputProps={{ type: 'number'}}
                                sx={{width:70}}
                                variant="standard"
                                placeholder="N/A"
                                size="small"
                                defaultValue={completionsCap}
                                onBlur={(e)=> {
                                    handleOfferChange(ID, 'CompletionsCap', e.target.value).then(res => {
                                        if(res?.status == "success"){
                                            setCompletionsCap(e.target.value)
                                        }
                                    });
                                }}
                            />
                        </div>
                    )
               
                }

                if (field === 'CompletionsCapDuration') {
                    
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Select
                                size="small"
                                variant="standard"
                                placeholder="N/A"
                                value={completionsCapDuration}
                                onChange={e => {
                                    handleOfferChange(ID, 'CompletionsCapDuration', e.target.value).then(res => {
                                        if(res?.status == "success"){
                                            setCompletionsCapDuration(e.target.value)
                                        }
                                    });
                                }}
                            >
                                <MenuItem value={1} key={1}>Daily</MenuItem>
                                <MenuItem value={2} key={2}>Monthly</MenuItem>
                                <MenuItem value={3} key={3}>Total</MenuItem>
                            </Select>
                        </div>
                    )
               
                }

                if (field === 'ReviewRequired') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                value={field}
                                checked={Boolean(checkBoxesValues[field])}
                                onChange={(el) => {
                                    if(Boolean(reviewAllowed)){
                                        offerOfDayChangeHandler(el)
                                        setReviewRequired(el.target.checked)
                                    }else{
                                        Alerts.showErrorAlert("'Review allowed' must be checked!")
                                    }
                                    
                                }}
                            />
                        </div>
                    )
                }

                if (field === 'ReviewAllowed') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <Checkbox
                                size="small"
                                className="grid-list_basic-cell-checkbox"
                                value={field}
                                checked={Boolean(checkBoxesValues[field])}
                                onChange={(el) => {
                                    offerOfDayChangeHandler(el)
                                    setReviewAllowed(el.target.checked)
                                    if(el.target.checked === false && Boolean(reviewRequired)){ // also turn off ReviewRequired field
                                        handleOfferChange(ID, "ReviewRequired", 0);
                                        setReviewRequired(0)
                                        setCheckBoxesValues(prev => {
                                            return { ...prev, ReviewRequired: 0 };
                                        });
                                    }
                                }}
                            />
                        </div>
                    )
                }

                if (field === 'ReviewTopicName') {
                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}
                            onClick={() => {
                                if(Boolean(reviewAllowed)){
                                    Alerts.showOfferReviewTopic(ReviewTopicID, (topic) => {
                                        setReviewTopicName(topic.name);
                                        handleOfferChange(ID, "ReviewTopicID", topic.ID);
                                    });
                                }
                            }}
                        >
                            {reviewTopicName ? reviewTopicName : Boolean(reviewAllowed) ? "Add" : "N/A" }
                        </div>
                    )
                }

                if (field === 'ExpirationDate') {

                    return (
                        <div className="grid-list_basic-cell" style={colWidth} key={index}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    format='YYYY-MM-DD'
                                    minDate={dayjs()}
                                    value={dayjs(ExpirationDate)}
                                    onChange={(newValue) => {
                                        const formattedDate = dayjs(newValue).format('YYYY-MM-DD 00:00:00');
                                        handleOfferChange(ID, "ExpirationDate", formattedDate);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    );

                }

                return (
                    <div className="desc grid-list_basic-cell" style={colWidth} key={index}>
                        <p className="desc">{item[field]}</p>
                    </div>
                )
            })}
        </>
    )
}
export default CellRow
